.tilegame {
  position: relative;
  display: grid;
  background-color: rgb(30, 30, 30);
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-size: 1rem;
  line-height: 1.1;
  color: white;
  margin-bottom: 40px;
}

.tg-button {
  background-color: rgb(90, 90, 90);
  font-size: clamp(0.7rem, 0.3824rem + 0.8824vw, 1rem);
  border: 2px solid white;
  border-radius: 5px;
  box-shadow: 3px 5px 5px rgba(30, 30, 30, 0.5);
  cursor: pointer;
  padding: 4px 10px;
  width: 80%;
  color: white;
}

.tg-button:hover {
  box-shadow: inset 3px 5px 5px rgba(30, 30, 30, 0.2);
  background-color: rgb(120, 120, 120);
}

.main {
  background-color: red;
}

.grid {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.title {
  background-color: black;
  margin-inline: auto;
}

.game {
  display: grid;
  grid-template-columns: repeat(12, min(50px, 8.33%));
  gap: 0px;
  width: min(75%, 600px);
}

.plate {
  aspect-ratio: 1;
  padding: 0;
  margin: 0;
}

.player-icon {
  background-image: url(/public/images/tilegame/player-blue.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.player-icon-blue {
  background-image: url(/public/images/tilegame/player-blue.png);
}

.player-icon-red {
  background-image: url(/public/images/tilegame/player-red.png);
}

.player-icon-yellow {
  background-image: url(/public/images/tilegame/player-yellow.png);
}

.player-icon-green {
  background-image: url(/public/images/tilegame/player-green.png);
}

.player-icon-teal {
  background-image: url(/public/images/tilegame/player-teal.png);
}

.player-icon-purple {
  background-image: url(/public/images/tilegame/player-purple.png);
}

.player-icon-orange {
  background-image: url(/public/images/tilegame/player-orange.png);
}

.key {
  background-image: url(/public/images/tilegame/key.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.floor-one {
  background-image: url(/public/images/tilegame/grass.png);
  background-attachment: fixed;
}

.wall-one {
  background: radial-gradient(green, rgb(0, 77, 0))
}

.floor-two {
  background-image: url(/public/images/tilegame/dirt.png);
  background-attachment: fixed;
}

.wall-two {
  background: radial-gradient(rgb(90,90,120), rgb(120,120,150))
}

.floor-three {
  background-image: url(/public/images/tilegame/ice.png);
  background-attachment: fixed;
}

.wall-three {
  background: radial-gradient(rgb(140, 140, 220), rgb(100, 100, 180));
}

.floor-four {
  background-image: url(/public/images/tilegame/rock.png);
  background-attachment: fixed;
}

.wall-four {
  background: radial-gradient(rgb(255, 106, 0), rgb(255, 38, 0));
}

.floor-five {
  background-image: url(/public/images/tilegame/space.png);
  background-attachment: fixed;
}

.wall-five {
  background: radial-gradient(rgb(138, 0, 145), rgb(81, 0, 44));
}

.control-panel {
  position: relative;
  width: min(25%, 200px);
  padding: 5px 10px;
  margin-inline: 2px;
  background-color: black;
  text-align: center;
  font-size: clamp(0.5rem, 0.7rem + 1.5vw, 1.5rem);
}

.control-panel p {
  margin: 20px 0;
}

.score, .high-score {
  font-size: clamp(1rem, 0.2588rem + 2.0588vw, 1.7rem);
}

.start-game-button, .stop-game-button {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -30%);
  font-size: clamp(0.5rem, 0.5rem + 2vw, 2rem);
}

.hidden {
  display: none;
}

.timer {
  position: absolute;
  bottom: 7%;
  left: 0;
  transform: translate(0%, -50%);
  width: 100%;
  font-size: clamp(0.5rem, 0.5rem + 2vw, 2rem);
}

.tip {
  position: absolute;
  bottom: 20%;
  left: 0;
  transform: translate(0%, -50%);
  font-size: clamp(0.75rem, 0.0618rem + 1.9118vw, 1.4rem);
}

.colour-select {
  margin-top: 10px;
  background-color: rgb(90, 90, 90);
  border: 2px solid white;
  border-radius: 5px;
  box-shadow: 3px 5px 5px rgba(30, 30, 30, 0.5);
  cursor: pointer;
  padding: 4px 5px;
  width: 60%;
  color: white;
  text-align: center;
}

.colour-select option:disabled {
  background-color: rgb(70, 70, 70);
}

.end-message {
  position: absolute;
  background-color: black;
  padding: 10px;
  width: 50%;
  max-width: 500px;
  transform: translate();
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 4px solid white;
  border-radius: 10px;
  text-align: center;
}

.end-message-message {
  font-size: clamp(0.8rem, 0.0588rem + 2.0588vw, 1.5rem);
}

.end-message h2 {
  font-size: clamp(1.2rem, 0.3529rem + 2.3529vw, 2rem);
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-weight: 400;
  border-bottom: none;
}

.end-message-score {
  font-size: clamp(0.8rem, 0.3765rem + 1.1765vw, 1.2rem);
}

.end-message button {
  width: 40%;
  margin-top: 10px;
}

/* @media screen size - Large */
@media only screen and (min-width: 992px) and (max-width: 1199.999px) {
  .control-panel p {
    margin: 17px 0;
  }
}

/* @media screen size - Medium */
@media only screen and (min-width: 768px) and (max-width: 991.999px) {
  .control-panel p {
    margin: 13px 0;
  }
}

/* @media screen size - Small */
@media only screen and (min-width: 576px) and (max-width: 767.999px) {
  .control-panel p {
    margin: 9px 0;
  }
}

/* @media screen size - Extra Small */
@media only screen and (max-width: 575.999px) {
  .control-panel p {
    margin: 5px 0;
  }
}