/* --- Home Page --- */

.home {
  margin-top: 0;
}

.home-title {
  font-size: clamp(3rem, -3.3529rem + 17.6471vw, 9rem);
  font-family: Agency FB, Agency FB Thin, sans-serif;
  line-height: 80%;
  padding: 0;
  border-bottom: none;
}

.home-subtitle {
  font-family: Agency FB, Agency FB Thin, sans-serif;
  font-size: clamp(1.5rem, -1.1471rem + 7.3529vw, 4rem);
  opacity: 0.6;
  border: none;
  margin-bottom: 100px;
}

.home-section {
  margin-bottom: 80px;
}

.home-section p {
  margin-bottom: 10px;
}

/* --------------------------------------------- */

/* @media screen size - Extra Large+ */
@media only screen and (min-width: 1120px) {

}

/* @media screen size - Large */
@media only screen and (min-width: 992px) and (max-width: 1199.999px) {

}

/* @media screen size - Medium */
@media only screen and (min-width: 768px) and (max-width: 991.999px) {

}

/* @media screen size - Small */
@media only screen and (min-width: 576px) and (max-width: 767.999px) {

}

/* @media screen size - Extra Small */
@media only screen and (max-width: 575.999px) {

}