/* --- About Layout Page --- */

.about-layout-nav {
  width: 50%;
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  margin: auto;
  padding-bottom: 10px;
}

.about-layout-nav a {
  margin: auto;
  color: #333;
  text-decoration: none;
  font-size: clamp(0.8rem, 0.0588rem + 2.0588vw, 1.5rem);
  font-weight: bold;
}

.about-layout-nav a:hover {
  opacity: 0.7;
}

/* --- Who Am I? Page --- */

.about-index p {
  margin-bottom: 20px;
}

.about-index-image {
  width: 30%;
  border: 3px solid #333;
  border-radius: 10px;
  margin: 40px 0 40px 0;
}

.about-index-section {
  margin: 0 auto 80px auto;
  
}

.about-index-section h1 {
  margin-top: 0;
}

.about-index-intro div {
  margin-top: 0px !important;
  margin-bottom: 40px;
}

/* --- Experience Page --- */

.about-experience-card {
  margin: 40px 20px 40px 20px;
}

.about-experience-card h2 {
  color: darkcyan;
  font-family: Agency FB, Agency FB Thin, sans-serif;
  border: none;
  margin: 0 auto 0 auto;
}

/* --- Skills Page --- */

.about-skills-container {
  display: grid;
  grid-template-columns: repeat(3, 33%);
}

.about-skills-container, .about-skilldetails-container {
  justify-items: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

.about-skills-intro {
  margin: 40px 0 40px 0;
}

.about-skills-card {
  text-decoration: none;
  align-content: center;
  background-color: darkcyan;
  width: 80%;
  height: 80px;
  padding: 3px;
  margin-bottom: 20px;
  border: solid 2px white;
  border-radius: 10px;
  color: white;
  font-size: clamp(0.8rem, 0.3765rem + 1.1765vw, 1.2rem);
  font-weight: bold;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.7);
}

.about-skills-card:hover {
  box-shadow: inset 0px 5px 5px -2px rgba(0, 0, 0, 0.7);
  opacity: 0.9;
}

.test {
  border: solid 3px white;
  box-shadow: inset 0px 5px 5px -2px rgba(0, 0, 0, 0.7);
}

.about-skills-list {
  margin: 20px auto 0 auto;
}

.about-skills-list p {
  font-size: clamp(0.8rem, 0.0588rem + 2.0588vw, 1.5rem);
  line-height: 200%;
}

.about-skills-softskills {
  margin-top: 40px;
  margin-bottom: 40px;
}

.about-skills-softskills p {
  margin-bottom: 10px;
}

.about-skilldetails {
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.about-skilldetails-text {
  text-align: center;
  margin-bottom: 40px;
}

.about-skilldetails-text h1 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.about-skilldetails-text h2 {
  margin-top: 40px;
  margin-bottom: 10px;
}

.about-skilldetails-text p {
  margin-bottom: 20px;
}

  .about-skilldetails-selected {
    background-color: rgba(0, 0, 0, 0.1);
  }

    .about-skilldetails-selected td a {
      color: darkcyan;
    }  
  
    .about-skilldetails-selected th {
      color: darkcyan;
    }

/* --------------------------------------------- */

/* @media screen size - Extra Large+ */
@media only screen and (min-width: 1120px) {

}

/* @media screen size - Large */
@media only screen and (min-width: 992px) and (max-width: 1199.999px) {
  
  .about-layout-nav {
    width: 65%;
  }

}

/* @media screen size - Medium */
@media only screen and (min-width: 768px) and (max-width: 991.999px) {

  .about-layout-nav {
    width: 80%;
  }
}

/* @media screen size - Small */
@media only screen and (min-width: 576px) and (max-width: 767.999px) {
  
  .about-layout-nav {
    width: 90%;
  }

  .about-index-image {
    width: 40%;
  }

  .about-skills-card a {
    height: 70px;
  }
}

/* @media screen size - Extra Small */
@media only screen and (max-width: 575.999px) {
  
  .about-layout-nav {
    width: 100%;
  }

  .about-index-image {
    width: 50%;
  }

  .about-skills-container {
    grid-template-columns: repeat(2, 50%);
  }

  .about-skills-card a {
    height: 60px;
  }
}